
import { defineComponent, reactive, toRefs } from 'vue'
import loginCore from './components/login.core.vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Login',
  components: { loginCore },
  setup() {
    const store = useStore()
    const data = reactive({
      nameEN: 'MANAGEMENT BACKGROUND',
      name: '出租车SaaS运营平台管理后台',
      welcome: '欢迎登录',
      isMobile: store.state.common.isMobile
    })
    return {
      ...toRefs(data)
    }
  }
})
