<template>
  <div class="main" :class="{ isMobile: isMobile }">
    <main-navbar />
    <div class="main-content--t">
      <main-sidebar />
      <main-content />
    </div>
  </div>
</template>
<script>
import MainNavbar from '@c/main-navbar'
import MainSidebar from '@c/main-siderbar'
import MainContent from '@c/main-content'
import debounce from 'lodash/debounce'
import { useStore } from 'vuex'
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  name: 'Main',
  components: {
    MainNavbar,
    MainSidebar,
    MainContent
  },
  setup() {
    const store = useStore()
    const data = reactive({
      isMobile: store.state.common.isMobile
    })

    // 窗口改变大小
    const windowResizeHandle = () => {
      store.state.sidebarFold =
        document.documentElement.clientWidth <= 992 || false
      window.addEventListener(
        'resize',
        debounce(() => {
          store.state.sidebarFold =
            document.documentElement.clientWidth <= 992 || false
        }, 150)
      )
    }
    windowResizeHandle()

    return {
      ...toRefs(data)
    }
  }
})
</script>
<style scoped>
.isMobile {
  overflow: auto !important;
}
.main-content--t {
  display: flex;
  height: calc(100vh - 64px);
  min-width: 1200px;
}
</style>
