/*
 * @Descritption:
 * @Author: wangzhenyu
 * @Date: 2021-04-20 16:20:49
 * @LastEditors: yyx
 * @LastEditTime: 2025-03-12 17:02:23
 */
export const setBaseUrl = () => {
  let baseUrl = ''
  let uploadUrl = ''
  const DomeHost = 'saas.demo.ccclubs.com'
  switch (window.location.host) {
    case 'saas.test.ccclubs.com':
      baseUrl = 'https://ccclubs-admin.test.ccclubs.com'
      uploadUrl = 'https://storeadm.test.ccclubs.com'
      break
    case DomeHost: // 演示环境
      uploadUrl = 'http://filecenter.demo.ccclubs.com'
      baseUrl = 'http://ccclubs-admin.demo.ccclubs.com'
      break
    default:
      baseUrl = 'https://cgj.ccclubs.com'
      uploadUrl = 'https://cgj.ccclubs.com'

      break
  }
  if (process.env.NODE_ENV === 'development') {
    baseUrl = 'https://ccclubs-admin.test.ccclubs.com'
    uploadUrl = 'https://storeadm.test.ccclubs.com'
  }
  return { uploadUrl, baseUrl }
}
