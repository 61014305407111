/*
 * @Description: config
 * @Author: yaoxiaoxiao
 * @Date: 2021-03-22 14:07:18
 * @LastEditors: yyx
 * @LastEditTime: 2025-03-13 10:29:46
 */
/**
 * @description: 账户校验
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @return {*}
 */
const validateUserName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('必填'))
  } else if (value.length > 53) {
    return callback(new Error('账户过长'))
  } else {
    return callback()
  }
}

/**
 * @description: 密码校验
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @return {*}
 */
const validatePass = (rule, value, callback) => {
  const len = value?.length
  if (!value) {
    return callback(new Error('必填'))
  } else if (len < 8) {
    return callback(new Error('请输入至少8位登录密码'))
  } else if (len > 16) {
    return callback(new Error('登录密码过长'))
  } else {
    return callback()
  }
}

// 表单校验规则
export const rules = {
  mobile: [{
    required: true,
    message: '必填',
    trigger: 'blur'
  }, {
    pattern: /^[1][0-9]{10}$/,
    message: '请输入11位手机号',
    trigger: 'blur'
  }],
  code: [{
    message: '必填',
    trigger: 'blur'
  }, {
    pattern: /^[0-9]{6}$/,
    message: '请输入6位短信验证码',
    trigger: 'blur'
  }
  ],
  username: [{
    required: true,
    validator: validateUserName,
    trigger: ['blur', 'change']
  }],
  password: [{
    required: true,
    validator: validatePass,
    trigger: ['blur', 'change']
  }],
  validateCode: [{
    required: true,
    message: '请输入正确验证码',
    trigger: ['blur', 'change']
  }]
}
