
import { ElMessage, ElMessageBox } from 'element-plus'
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue'
import { rules } from '../config'
import { resetRouter, isDevRouter } from '@/router/index'
import { useStore } from 'vuex'
import { generateValidateCodeAPI } from '@/api/common'
import { useRouter } from 'vue-router'
import config from '@/config'
import { toResult } from '@/utils/toResult'

export default defineComponent({
  name: 'loginCore',
  props: {
    isMobile: Boolean
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const data = reactive({
      rules, // 表单校验规则
      // 表单数据
      formData: {
        username: '',
        password: '',
        validateCode: ''
      },
      validImg: '', //  证码图片
      isLoginBtnDisabled: true, // 是否禁用登录按钮
      formRef: ref<HTMLElement | null>(null)
    })

    const handleValidateCode = async p => {
      const [err, res] = await toResult(generateValidateCodeAPI(p))
      if (err) return
      data.validImg = res.validateCode
    }

    // 登录之后
    const LoginAfter = () => {
      store.dispatch('user/getMenuResource').then(res => {
        if (res.limitTreeVo.length > 0 || res.permission.length > 0) {
          router.push({ name: config.homeRoute })
        } else {
          ElMessage.warning('没有权限!')
        }
      })
    }

    // 确认登录
    const loginConfirm = p => {
      const { tmpToken, upgradeToken } = p
      store
        .dispatch('user/confirmLogin', { tmpToken, upgradeToken, type: 1 })
        .then(res => {
          console.log(res)
          if (upgradeToken) LoginAfter()
        })
    }

    // 监听输入用户名
    const BlurUserName = () => {
      const p = {
        accountName: data.formData.username,
        refresh: true
      }

      if (data.formData.username) {
        handleValidateCode(p)
      }
    }

    // 登录
    const handleLogin = () => {
      store
        .dispatch('user/login', { ...data.formData, isAdmin: true })
        .then(res => {
          if (res.code === 901) {
            ElMessage.error(res.msg)
            BlurUserName()
            return false
          }
          // 是否继续登录：true-继续登录【请求确认】，false-取消登录【直接登录】，特么的这逻辑我都是蒙的
          if (res.upgradeToken) {
            ElMessageBox.confirm(
              '您的账号已登录，如确定登录，原登陆终端将会自动登出。',
              '',
              {
                confirmButtonText: '确定登录',
                cancelButtonText: '取消登录',
                showClose: false,
                closeOnClickModal: false,
                closeOnPressEscape: false
              }
            )
              .then(() => {
                // 确认登录
                loginConfirm(res)
              })
              .catch(() => {
                loginConfirm({ tmpToken: res.tmpToken, upgradeToken: false })

                for (const key in config) {
                  localStorage.removeItem(config[key])
                }
              })
          } else {
            LoginAfter()
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    /**
     * @description: 执行登录
     * @param {*}
     * @return {void}
     */
    const onSubmit = () => {
      const ref: any = data.formRef
      ref.validate((vaild: boolean) => {
        if (vaild) {
          handleLogin()
        }
      })
    }

    /**
     * @description: change 普通用户表单
     * @param {*}
     * @return {void}
     */
    const changeUser = () => {
      if (data.formData.username !== '' && data.formData.password !== '') {
        data.isLoginBtnDisabled = false
      } else {
        data.isLoginBtnDisabled = true
      }
    }

    /**
     * @description: 清空input
     * @param {*}
     * @return {void}
     */
    const clearInput = () => {
      data.isLoginBtnDisabled = true
    }
    onMounted(() => {
      isDevRouter || resetRouter()
    })
    return {
      ...toRefs(data),
      onSubmit,
      clearInput,
      changeUser,
      handleValidateCode,
      BlurUserName
    }
  }
})
